import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Footer = ({ language }) => (
  <footer className="text-white pb-8 bg-secondary">
    <div className="container">
      <div className="md:flex md:flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="md:flex md:flex-col">
            <div className="md:flex">
              <img
                alt="HiBot logo"
                className="block"
                src={require("../img/hb-symbol.svg")}
                style={{ width: 90, height: 90 }}
              />
              <div className="md:mt-10 md:ml-3 text-center md:text-left">
                <Link
                  to={`${language === "en" ? "" : "/ja"}/products`}
                  className="text-white m-3 inline-block hover:text-primary"
                >
                  Products
                </Link>{""}
                <Link
                  to={`${language === "en" ? "" : "/ja"}/services`}
                  className="text-white m-3 inline-block hover:text-primary"
                >
                  Services
                </Link>{" "}
                <Link
                  to={`${language === "en" ? "" : "/ja"}/solutions`}
                  className="text-white m-3 inline-block hover:text-primary"
                >
                  Solutions
                </Link>{" "}
                <Link
                  to={`${language === "en" ? "" : "/ja"}/about`}
                  className="text-white m-3 inline-block hover:text-primary"
                >
                  Hibot
                </Link>{" "}
                <Link
                  to={`${language === "en" ? "" : "/ja"}/blog`}
                  className="text-white m-3 inline-block hover:text-primary"
                >
                  News
                </Link>
              </div>
            </div>
            <div className="md:grid md:grid-rows-4 md:grid-cols-4 md:gap-2 md:grid-flow-col">
              <div className="md:row-start-2 md:row-span-2 md:col-start-2 md:col-span-2">
                <img
                    alt="ISMS"
                    className="block"
                    src={require("../img/ISMS_ANAB_ISMS-AC_color_Number.jpg")}
                  />
              </div>
            </div>
          </div>

          <div className="text-center md:text-right mt-5">
            {/* <img
                alt="HiBot logotype"
                className="block float-right"
                src={require("../img/hb-logotype.svg")}
                style={{ width: 90, height: 90 }}
              /> */}
            <h3> 
              <img
                alt="HiBot logotype"
                className="inline"
                src={require("../img/hb-logotype.svg")}
                style={{ width: 120, height: 30 }}
              />
              </h3>  
            <p className="text-white">
              Watanabe Corp. Bldg. 4F
              <br />
              5-9-15 Kita Shinagawa, Shinagawa-ku
              <br />
              141-0001 Tokyo, Japan
            </p>
            <p className="text-white">
              〒141-0001
              <br />
              東京都品川区北品川5-9-15
              <br />
              渡辺コーポレーションビル4階
            </p>
            <p className="text-white ">
              TEL: +81-(3)-5791-7526
            </p>
            <p className="text-white">
              <Link
                  to={`${language === "en" ? "" : ""}/about#Access`}
                  className="text-teal hover:text-primary">
                  Access | &nbsp; 
              </Link>
              <Link
                  to={`${language === "en" ? "" : "/ja"}/get-in-touch`}
                  className="text-teal hover:text-primary">
                  Contacts | &nbsp;
              </Link>
              <Link
                  to={`${language === "en" ? "" : "/ja"}/careers`}
                  className="text-teal hover:text-primary">
                  Careers 
              </Link>
            </p>
          </div>
        </div>

        <div className="md:flex">
          <div className="md:w-1/4 mb-3 md:mb-0 text-center md:text-left">
            <a href="https://www.youtube.com/channel/UCAcGEtnQiveDoWreWrkNMsA/featured" target="blank" className="mr-5">
              <img
                src={require("../images/icons/icon-youtube.svg")}
                alt="YouTube"
                title="YouTube"
                className="inline-block"
              />
            </a>
            <a href="https://www.linkedin.com/company/hibot/" target="blank" className="mr-5">
              <img
                src={require("../images/icons/icon-linkedin.svg")}
                alt="LinkedIn"
                title="LinkedIn"
                className="inline-block"
              />
            </a>
            <a href="https://www.facebook.com/HiBotCo" target="blank">
              <img
                src={require("../images/icons/ico-facebook.svg")}
                alt="Facebook"
                title="Facebook"
                className="inline-block"
              />
            </a>
          </div>
          <div className="md:w-3/4">
            <p className="text-white text-center mb-0">
              © hibot 2022 - Copyrights - <Link
                to={`${language === "en" ? "" : "/ja"}/privacy-policy`}
                className="text-teal hover:text-primary"
              >
                Privacy Policy
              </Link> - <Link
                to={`${language === "en" ? "" : "/ja"}/information-security-basic-policy`}
                className="text-teal hover:text-primary"
              >
                Information security basic policy
              </Link>
            </p>
          </div>
          <div className="md:w-1/4"></div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  language: PropTypes.string,
};

export default Footer;
