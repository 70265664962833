import React from "react";
import PropTypes from "prop-types";
import IosChatbubblesOutline from "react-ionicons/lib/IosChatbubblesOutline";
import { Link } from "gatsby";

const Notice = ({ onClose, language }) => (
  <div className="notice">
    <div className="max-w-4xl mx-auto md:flex items-center">
      <div className="flex">
        <IosChatbubblesOutline
          className="inline mr-4"
          color="white"
          fontSize="30px"
        />
        <h6 className="text-white text-sm md:mb-0 mr-4 flex-1">
          {language === "en" ? (
            <>
              With your consent, we would like to use cookies to enhance your
              experience with our services, for analytics, and for advertising
              purposes. You can learn more about this in our{" "}
              <Link
                to="/privacy-policy/"
                className="text-teal underline hover:text-teal-light"
              >
                {" "}
                Privacy Policy.
              </Link>
            </>
          ) : (
            <>
              With your consent, we would like to use cookies to enhance your
              experience with our services, for analytics, and for advertising
              purposes. You can learn more about this in our{" "}
              <Link
                to="/ja/privacy-policy/"
                className="text-teal underline hover:text-teal-light"
              >
                {" "}
                Privacy Policy.
              </Link>
            </>
          )}
        </h6>
      </div>
      <div className="text-center">
        <button className="btn btn-secondary" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  </div>
);

Notice.propTypes = {
  onClose: PropTypes.function,
  language: PropTypes.string,
};

export default Notice;
