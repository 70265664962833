import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
// import IosBuildOutline from "react-ionicons/lib/IosBuildOutline";
import Footer from "../components/Footer";
import Modal from "react-modal";
import ModalContext from "../contexts/ModalContext";
import Notice from "../components/Notice";
import { setCookie, getCookie } from "../utils/utils";

const NOTICE_COOKIE_KEY = "notice-1";

const customStyles = {
  content: {
    width: "100%",
    maxWidth: 800,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99999,
    border: "none",
  },
};
function Layout({ children, language, navbarBlur }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (!getCookie(NOTICE_COOKIE_KEY)) {
      openNotice();
    }
  }, []);

  function openModal(content) {
    setModalContent(content);
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openNotice() {
    setNoticeOpen(true);
  }

  function closeNotice() {
    setCookie(NOTICE_COOKIE_KEY, "accepted");
    setNoticeOpen(false);
  }

  const onScroll = () => {
    const navbar = document.getElementById("navbar");

    if (navbarBlur) {
      return;
    }

    if (window.scrollY > 0) {
      navbar.classList.add("navbar-blur");
    } else {
      navbar.classList.remove("navbar-blur");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        openModal: openModal,
      }}
    >
      <div id="top" data-lang={language}>
        <nav
          id="navbar"
          className={`navbar ${navbarBlur ? "navbar-blur" : ""} pl-0, pr-3`}
        >
          <Link to={language === "en" ? "/" : "/ja/home"}>
            <img
              alt="HiBot logo"
              className="block absolute top-0 left-0 h-full "
              src={require("../img/hb-newlogo.svg")}
            />
          </Link>
          <div className="ml-auto flex items-center">
            {language === "en" ? (
              <Link
                to="/get-a-quote"
                className="btn btn-sm btn-primary hidden md:inline-flex"
              >
                Enquires
                {/* <IosBuildOutline
                  className="inline ml-1"
                  color="white"
                  fontSize="30px"
                /> */}
              </Link>
            ) : (
              <Link
                to="/ja/get-a-quote"
                className="btn btn-sm btn-primary hidden md:inline-flex"
              >
                お問い合わせ
                {/* <IosBuildOutline
                  className="inline ml-1"
                  color="white"
                  fontSize="30px"
                /> */}
              </Link>
            )}
            <span className="inline-block ml-8 mr-5 relative">
              <button
                onClick={() => setLanguageMenuOpen(!isLanguageMenuOpen)}
                className="text-white"
              >
                {language === "en" ? "En" : "Jp"}
              </button>
              <div
                className={`language-menu ${
                  isLanguageMenuOpen ? "language-menu--open" : ""
                }`}
              >
                {language === "en" ? (
                  <Link to="/ja/home" className="navbar-link">
                    Japanese
                  </Link>
                ) : (
                  <Link to="/" className="navbar-link">
                    English
                  </Link>
                )}
              </div>
            </span>
            <button
              className={`hamburger hamburger--slider ${
                isMobileMenuOpen ? "is-active" : ""
              }`}
              type="button"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              <span className="mr-3 text-white">Menu</span>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </nav>
        <div
          className={`mobile-menu ${
            isMobileMenuOpen ? "mobile-menu--open" : ""
          } flex flex-col overflow-y-scroll`}
        >
          <div
            className="flex flex-col items-center lg:items-end lg:mr-24 my-auto"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          >
            <Link
              to={`${language === "en" ? "" : "/ja"}/products`}
              className="mobile-menu-link"
            >
              Products
            </Link>
            <div className="mobile-menu-divider"></div>
            <Link
              to={`${language === "en" ? "" : "/ja"}/services`}
              className="mobile-menu-link"
            >
              Services
            </Link>
            <div className="mobile-menu-divider"></div>
            <Link
              to={`${language === "en" ? "" : "/ja"}/solutions`}
              className="mobile-menu-link"
            >
              Solutions
            </Link>
            <div className="mobile-menu-divider"></div>
            <Link
              to={`${language === "en" ? "" : "/ja"}/about`}
              className="mobile-menu-link"
            >
              Hibot
            </Link>
            <div className="mobile-menu-divider"></div>
            <Link
              to={`${language === "en" ? "" : "/ja"}/blog`}
              className="mobile-menu-link"
            >
              News
            </Link>
            <div className="mobile-menu-divider"></div>
            <Link
              to={`${language === "en" ? "" : "/ja"}/get-in-touch`}
              className="mobile-menu-link"
            >
              Get in touch
            </Link>
          </div>
        </div>
        <main>{children}</main>
        <div
          className="py-12 bg-light-pink"
          style={{ backgroundColor: "#F5E7E7" }}
        >
          <div className="container max-w-4xl">
            <div className="md:flex">
              <div className="md:w-1/3">
                <h4 className="font-light mb-0">
                  {language === "en" ? (
                    <>
                      Subscribe to
                      <br/>
                      our newsletter
                    </>
                  ) : (
                    "ニュースレターにご登録ください"
                  )}
                </h4>
              </div>
              <div className="md:w-2/3">
                <form
                  action="https://hibot.us19.list-manage.com/subscribe/post?u=f2bcfc4bf7904b85419f8cf48&amp;id=eb2bdd6bf0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div className="flex overflow-hidden bg-white border border-gray-400 max-w-lg mx-auto rounded-full h-12 shadow-sm">
                    <img
                      alt="E-mail Icon"
                      className="block ml-4 mr-3"
                      src={require("../images/icons/icon-email.svg")}
                      style={{ width: 30 }}
                    />
                    <input
                      placeholder={
                        language === "en"
                          ? "Enter your email here"
                          : "メールアドレス"
                      }
                      className="flex-1 outline-none bg-white"
                      type="email"
                      name="EMAIL"
                      required
                    />
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_f2bcfc4bf7904b85419f8cf48_eb2bdd6bf0"
                        tabIndex="-1"
                        value=""
                      />
                    </div>
                    <button className="hidden sm:block bg-primary text-white rounded-full px-6 -m-px outline-none">
                      {language === "en" ? "Get informed" : "登録する"}
                    </button>
                  </div>
                </form>
                <button className="sm:hidden bg-primary text-white mt-2 rounded-full px-6 py-3 outline-none">
                  {language === "en" ? "Get informed" : "登録する"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*
        <Link to="#top" className="backtop-btn">
          <img
            alt="Go to top"
            src={require("../images/icons/icon-backtop.svg")}
            style={{ width: 50, height: 50 }}
          />
        </Link> */}

        <Footer language={language} />

        {/* MODAL */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <button onClick={closeModal} className="uppercase mb-1 font-bold">
            close
          </button>
          <div
            id="modalContent"
            dangerouslySetInnerHTML={{
              __html: modalContent,
            }}
          ></div>
        </Modal>

        {/* NOTICE */}
        {isNoticeOpen && <Notice onClose={closeNotice} language={language} />}
      </div>
    </ModalContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string,
  navbarBlur: PropTypes.bool,
};

export default Layout;
